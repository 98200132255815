// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "#4f2a76",
          backgroundImage: size ? `url(${require("assets/images/Asset1.png")})` : ``,
          backgroundSize: size ? "100%" : "100%",
          backgroundRepeat: "no-repeat",

          // marginTop: size ? "7.8rem" : "0rem",
          paddingBottom: size ? "8.2rem" : "10rem",
        }}
        data-parallax={true}
      >
        {/* <div className="overlay-primary"></div> */}
        {size ? (
          <Container>
            <Row className="align-items-center justify-content-start">
              <Col md={6} xs={12} className="text-left">
                <img
                  src="/logo2025.png"
                  width="100%"
                  alt="main logo"
                  className="pt-5"
                />
                <h3 className="text-white text-400 text-right mt-0">
                  {/* 22<sup>nd</sup> */}
                  {/* May, 2025 */}
                  14ᵗʰ May 2025
                </h3>

                <Container fluid className="pt-3">
                  <Row>
                    <Col lg={12} className="pb-3 px-0 text-right">
                      <Button
                        href="/register"
                        className="navbar-item-custom text-400 text-dark rounded-0 "
                        style={{
                          background: "#e9ad4a",
                          border: "2px solid #000",
                        }}
                        size="sm"
                      >
                        <span
                          style={{
                            color: "#000",
                            textTransform: "none",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          className="text-600"
                        >
                          REGISTER <i class="fa fa-arrow-right" />
                        </span>
                      </Button>
                      <Button
                        href="/sponsor"
                        className="navbar-item-custom text-400 text-dark rounded-0"
                        style={{
                          background: "#e9ad4a",
                          border: "2px solid #000",
                        }}
                        size="sm"
                      >
                        <span
                          style={{
                            color: "#000",
                            textTransform: "none",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          className="text-600"
                        >
                          SPONSOR NOW
                          <i class="fa fa-arrow-right" />
                        </span>
                      </Button>
                      <Button
                        href="/download-report"
                        className="navbar-item-custom text-400 text-dark rounded-0 "
                        style={{
                          background: "#e9ad4a",
                          border: "2px solid #000",
                        }}
                        size="sm"
                      >
                        <span
                          style={{
                            color: "#000",
                            textTransform: "none",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          className="text-600"
                        >
                          DOWNLOAD REPORT <i class="fa fa-arrow-right" />
                        </span>
                      </Button>
                    </Col>
                    <Col lg={12} className="px-0 text-center"></Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <Container>
              <img
                src={require("assets/images/Asset1Mobile.png")}
                width="100%"
                alt="main logo"
                className="pt-5"
                style={{ marginLeft: "15px" }}
              />
              <Row className="align-items-center justify-content-start">
                <Col md={7} xs={12} className="text-left">
                  <img
                    src="/logo2025.png"
                    width="100%"
                    alt="main logo"
                    className="pt-5"
                  />
                  <h3 className="text-white text-400 text-right mt-0">
                    {/* May, 2025 */}
                    14ᵗʰ May 2025
                  </h3>
                  <Container fluid className="pt-3">
                    <Row>
                      <Col lg={12} className="pb-3 px-0 text-right">
                        <Button
                          href="/register"
                          className="navbar-item-custom text-400 text-dark rounded-0 "
                          style={{
                            background: "#e9ad4a",
                            border: "2px solid #000",
                          }}
                          size="lg"
                        >
                          <span
                            style={{
                              color: "#000",
                              textTransform: "none",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                            className="text-600"
                          >
                            REGISTER NOW <i class="fa fa-arrow-right" />
                          </span>
                        </Button>
                        <Button
                          href="/sponsor"
                          className="navbar-item-custom text-400 text-dark rounded-0"
                          style={{
                            background: "#e9ad4a",
                            border: "2px solid #000",
                          }}
                          size="lg"
                        >
                          <span
                            style={{
                              color: "#000",
                              textTransform: "none",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                            className="text-600"
                          >
                            BECOME A SPONSOR
                            <i class="fa fa-arrow-right" />
                          </span>
                        </Button>
                      </Col>
                      <Col lg={12} className="pb-3 px-0 text-right">
                        <Button
                          href="/download-report"
                          className="navbar-item-custom text-400 text-dark rounded-0 "
                          style={{
                            background: "#e9ad4a",
                            border: "2px solid #000",
                          }}
                          disabled
                          size="lg"
                        >
                          <span
                            style={{
                              color: "#000",
                              textTransform: "none",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                            className="text-600"
                          >
                            DOWNLOAD YAAP REPORT <i class="fa fa-arrow-right" />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
    </>
  );
}

export default LandingPageHeader;
