import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";
import { DataContext } from "DataContainer";
import { groupBy } from "views/content";

function Sponsor() {
  const { sponsors: allSponsors } = useContext(DataContext);
  const rows = groupBy("panel")(allSponsors);
  if (Object.keys(rows).length < 1) {
    return null;
  }
  if (allSponsors.length === 0) {
    return null;
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "#4f2a76",
        }}
        className="section"
        data-parallax={true}
        id="partners2025"
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-300 text-uppercase text-center text-white mb-5">
                2025 Sponsors & Partners
              </h1>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col xs={8} sm={6} lg={4} className={"ml-auto mr-auto pb-3"}>
              <a href={`/sponsors/BRAZE`}>
                <h3 className="text-400 text-white text-center mb-3">CUSTOMER ENGAGEMENT PARTNER</h3>
                <Card className="rounded-0">
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <img src="/images/braze.jpg" alt="braze" width="100%" className="rounded-0" />
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </a>
            </Col>

            <Col xs={8} sm={6} lg={4} className={"ml-auto mr-auto pb-3"}>
              <a href={`https://www.branch.io/`}>
                <h3 className="text-400 text-white text-center mb-3">STRATEGIC PARTNER</h3>
                <Card className="rounded-0">
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <img src="/images/branch.png" alt="braze" width="100%" className="rounded-0" />
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </a>
            </Col>
          </Row>

          <Row id="partners2024">
            <Col lg={12}>
              <h1 className="text-300 text-uppercase text-center text-white mb-5">
                {/* 2024 Partners */}
                Past Sponsors & Partners
              </h1>
            </Col>
          </Row>
          
          {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"}>
                  <a href={`/sponsors/${s.slug}`}>
                    <h3 className="text-400 text-white text-center mb-3">{s.type}</h3>
                    <Card className="rounded-0">
                      <Container fluid>
                        <Row>
                          <Col lg={12}>
                            <img src={s.image} alt={s.name} width="100%" className="rounded-0" />
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
