export const oldToken =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiNzk5NTg2ZTgtY2FmOS0xMWVkLWFmYTEtMDI0MmFjMTIwMDAyIiwiY3VzdG9tZXJJZCI6IjY3MDk5ZGU4LWNhZjktMTFlZC1hZmExLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL2toYWxlZWp0aW1lc2V2ZW50cy5jb20iLCJpYXQiOjE2Nzk3NDExODQsImF1ZCI6ImV4cC5jb20iLCJpc3MiOiJleHAtcHJvdmlkZXIiLCJzdWIiOiJhZG1pbkBleHAuY29tIn0.JYhXhvmsAXTkSzUHax-_mSGoAhHvqB134tiSHe-El4UP4V5D9_M_Q1txbYyNlbTFspPnN_a4heoRoqEINKIFJKvJG9GBtTSf8o4_d3iaMekMGsxdFE6Hlz4JPv9IG7gwerJYhrxO13GYhPEMNA_OuJ8hLJQ9VYKCzk0lkYF4Ni8";

// export const apiURL = "https://api.rapid-events.com";
export const apiURL = "/";

export const token =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiNzk5NTg2ZTgtY2FmOS0xMWVkLWFmYTEtMDI0MmFjMTIwMDAzIiwiY3VzdG9tZXJJZCI6IjY3MDk5ZGU4LWNhZjktMTFlZC1hZmExLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL3RyaWJlLmtoYWxlZWp0aW1lc2V2ZW50cy5jb20iLCJpYXQiOjE3MDQ2MzAzMjQsImF1ZCI6ImV4cC5jb20iLCJpc3MiOiJleHAtcHJvdmlkZXIiLCJzdWIiOiJhZG1pbkBleHAuY29tIn0.D2zKd3kocWlsouOPBhFiIVtx-uXY8grCXEd5Z-eToLTqGToJpM19OxN2rUvKHOiKI43e6vvbycYvHJOI8rDeiGEx8kCbVPC4J1tTeATE8V_6WXE4F21jXsFprFEtkw5rFo_lC4si8wsCjqVUr6Z0qv-adzveino5kDoRGzR09uI";
// export const apiURL = "http://localhost:3012";
//
