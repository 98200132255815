import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";

import CookiePolic from "components/CookiePolicy";

function CookiePolicy() {
    return (
        <>
            <Navbar />
            <ProfilePageHeader title={"Cookie Policy"} bgColor="#fff" />
            <CookiePolic />
            <Footer />
        </>
    );
}

export default CookiePolicy;
